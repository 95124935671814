import classNames from 'classnames';

import Button from '@components/Abm/Button';
import ScheduleACall from '@components/Registration/ScheduleACall';
import SectionContainer from '@components/Section/Container';
import Section from '@components/Section/Section';
import Text from '@hiredigital/ui/Text/Text';
import Styles from './Styles.module.scss';

const AgencyLogoSection = ({ section, ind, isScheduleCallSection, isStandalonePage }) => {
  return (
    <SectionContainer
      className={classNames(
        Styles.container,
        isScheduleCallSection ? Styles.scheduleCallContainer : Styles.agencyLogoContainer
      )}
      key={ind}>
      <Section
        className={classNames(
          isScheduleCallSection ? Styles.scheduleCallSection : Styles.agencyLogoSection,
          isStandalonePage && Styles.isStandalonePage
        )}>
        {section?.title && (
          <Text element='h2' className={Styles.agencyLogoTitle}>
            {section?.title}
          </Text>
        )}
        {isScheduleCallSection && (
          <>
            {section?.scheduleCallSectionSubtitle && (
              <div className={Styles.scheduleCallSectionSubtitle}>
                {section?.scheduleCallSectionSubtitle}
              </div>
            )}
            <ScheduleACall
              className={Styles.scheduleCallForm}
              inputClassName={Styles.scheduleCallInput}
              phoneInputClassName={Styles.scheduleCallPhoneInput}
              iframeClassName={Styles.scheduleCallHubspotIframe}
              button={Button}
              buttonClassName={Styles.scheduleCallButton}
            />
          </>
        )}
        <div
          className={
            isScheduleCallSection
              ? Styles.scheduleCallLogosContainer
              : Styles.agencyLogoLogosContainer
          }>
          {isScheduleCallSection && (
            <div className={Styles.scheduleCallLogoHeading}>{section?.scheduleCallLogoHeading}</div>
          )}
          {section?.logos?.map((logo, index) => (
            <img
              className={Styles.agencyLogo}
              key={index}
              loading='lazy'
              src={`/logos/white/${logo}.svg`}
              alt={logo}
            />
          ))}
        </div>
      </Section>
    </SectionContainer>
  );
};

export default AgencyLogoSection;
