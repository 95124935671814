import { request } from './utils';

export const postLogin = async (data, config = undefined) => {
  const url = `accounts/login/`;
  return await request.post(url, data, config);
};

export const postRegistration = async (data, config = undefined) => {
  const url = `accounts/registration/`;
  return await request.post(url, data, config);
};

export const getInvitation = async (uuid, config) => {
  const url = `public/invitations/${uuid}/`;
  return await request.get(url, config);
};

export const postConfirmUserPassword = (data, config = undefined) => {
  const url = `accounts/registration/confirm-user-password/`;
  return request.post(url, data, config);
};

export const postValidateUidToken = (data, config = undefined) => {
  const url = `accounts/registration/validate-uid-token/`;
  return request.post(url, data, config);
};

export const postCompanyOnboard = (data, config = undefined) => {
  const url = `public/onboard/`;
  return request.post(url, data, config);
};

export const putCompanyOnboard = (uuid, data, config = undefined) => {
  const url = `public/onboard/${uuid}/`;
  return request.put(url, data, config);
};

export const getCompanyOnboard = (uuid, config = undefined) => {
  const url = `public/onboard/${uuid}/`;
  return request.put(url, config);
};
