/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect } from 'react';
import { useRouter } from 'next/router';
import Script from 'next/script';

import Cookies from 'universal-cookie';
import classNames from 'classnames';
import { validateCorporateAndValidEmail, identifyHubspotUser } from '@helpers/forms';
import { OrganizationType } from '@hiredigital/lib/helpers/marketingEnum';
import Button from '@hiredigital/ui/Button';
import TextInput from '@hiredigital/ui/Input/SimpleTextInput';
import PhoneNumber from '@hiredigital/ui/PhoneNumber';
import TextArea from '@hiredigital/ui/Input/TextArea';
import Text from '@hiredigital/ui/Text/Text';
import InputContainer from '@hiredigital/ui/Form/InputContainer';

import { postCompanyOnboard } from '@apis/auth';
import { sendEvent } from '../../lib/gtm.js';
import { getCountries } from '@apis/common';
import { useCommon } from '../../shared/context/common';

import { submitHubspotForm, getNameParts } from './common/helpers';

import SharedStyles from './Registration.module.scss';
import Styles from './ScheduleACall.module.scss';

const cookies = new Cookies();

const ScheduleACall = ({
  emailId,
  insideBanner = false,
  className,
  inputClassName,
  phoneInputClassName,
  iframeClassName,
  button: CustomButton,
  buttonClassName,
}) => {
  const router = useRouter();
  const common = useCommon();
  const [hubspotUrl, setHubspotUrl] = useState(process.env.NEXT_PUBLIC_HUBSPOT_ROUND_ROBIN);

  const [restQuery, setRestQuery] = useState();
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState(emailId || '');
  const [company, setCompany] = useState('');
  const [phone, setPhone] = useState('');
  const [phoneCountry, setPhoneCountry] = useState();
  const [requirements, setRequirements] = useState();
  const [slug, setSlug] = useState();
  const [submitted, setSubmitted] = useState(false);
  const [emailValid, setEmailValid] = useState(true);
  const [processed, setProcessed] = useState(false);
  const [loading, setLoading] = useState(false);
  const [corporateValid, setCorporateValid] = useState(false);
  const [messageSent, setMessageSent] = useState(false);
  const [showCompanyLogin, setShowCompanyLogin] = useState(false);
  useEffect(() => {
    const handleMessage = (e) => {
      if (e.origin !== 'https://meetings.hubspot.com') return false;
      if (e.data.meetingBookSucceeded) {
        sendEvent({
          event: 'hubspot_meeting_booked',
        });
        sendEvent({ event: 'GTM-EntLeadGenConfirm' });
      }
    };

    window.addEventListener('message', handleMessage);

    return () => {
      window.removeEventListener('message', handleMessage);
    };
  }, []);

  useEffect(() => {
    if (common?.systemCountry) {
      setPhoneCountry(common?.systemCountry);
    }
  }, [common]);

  useEffect(() => {
    const element = document.getElementById('ctaContainer');
    if (element != null && insideBanner && !!email) {
      element.scrollIntoView({ block: 'center', inline: 'center' });
    }
  }, [email]);

  useEffect(() => {
    setEmail(emailId);
  }, [emailId]);

  useEffect(() => {
    if (messageSent) {
      setTimeout(() => {
        // We set a delay to ensure that the iframe element parent is in the DOM
        loadScript();
      }, 1000);
    }
  }, [messageSent]);

  const loadScript = (callback) => {
    console.log('loading script');
    const existingScript = document.getElementById('embed-script');
    if (!existingScript) {
      const script = document.createElement('script');
      script.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
      script.id = 'embed-script';
      document.body.appendChild(script);
      setTimeout(loadScript, 1000);
    }
  };

  useEffect(() => {
    const { name, email, company, phone, requirements, slug, ...rq } = router.query;
    if (name) setFullName(name);
    if (email) setEmail(email);
    if (company) setCompany(company);
    if (phone) setPhone(phone);
    if (requirements) setRequirements(requirements);
    if (slug) setSlug(slug.join ? slug.join('/') : slug);
    if (rq) setRestQuery(rq);
  }, [router.query]);

  useEffect(() => {
    setEmailValid(true);
    setCorporateValid(true);
  }, [email]);

  useEffect(() => {
    setProcessed(false);
  }, [fullName, email, company, phone]);

  const checkEmail = (email) => {
    const validator = validateCorporateAndValidEmail(email);
    setEmailValid(validator.valid);
    setCorporateValid(validator.corporate);
    return validator.valid && validator.corporate;
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter') {
      handleSubmit();
    }
  };

  const handleSubmit = async (e) => {
    if (e) {
      e.preventDefault();
      e.stopPropagation();
    }
    setSubmitted(true);

    const pageUrl = window.location.href;
    const pageName = document.title;

    const isEmailValid = !!email && checkEmail(email);

    if (isEmailValid && fullName) {
      setLoading(true);
      setShowCompanyLogin(false);
      const { firstName, lastName } = getNameParts(fullName);

      const data = {
        name: company,
        user_first_name: firstName,
        user_last_name: lastName,
        user_email: email,
        phone_number: `${phoneCountry?.dialCode} ${phone}`,
        requirements,
        type: OrganizationType.ENTERPRISE.id,
        site: 2,
        promotion: new URLSearchParams(restQuery).toString(),
      };

      identifyHubspotUser({
        email,
        name: fullName,
        company,
        requirements,
        phone_number: `${phoneCountry?.dialCode} ${phone}`,
        type: OrganizationType.ENTERPRISE.id,
      });
      sendEvent({ event: 'GTM-EntLeadGen' });

      // Identify User Block
      try {
        const hubspotData = {
          ...router.query,
          fullName,
          email,
          company,
          phone,
          requirements,
          work_email: email,
          site: 'Poly',
          slug,
        };
        submitHubspotForm(
          process.env.NEXT_PUBLIC_ENTERPRISE_FORMID,
          pageUrl,
          pageName,
          hubspotData
        );
      } catch (err) {
        console.error(err);
      }

      let extraData = '';

      if (firstName) extraData += `&firstName=${encodeURIComponent(firstName)}`;
      if (lastName) extraData += `&lastName=${encodeURIComponent(lastName || 'New Contact')}`;
      if (email) extraData += `&email=${encodeURIComponent(email)}`;
      if (phone) extraData += `&phone=${encodeURIComponent(phoneCountry?.dialCode + '' + phone)}`;
      if (requirements) extraData += `&requirements=${encodeURIComponent(requirements)}`;
      if (slug) extraData += `&slug=${encodeURIComponent(slug)}`;

      if (restQuery) {
        setHubspotUrl(`${hubspotUrl}${extraData}&${new URLSearchParams(restQuery).toString()}`);
      } else {
        setHubspotUrl(`${hubspotUrl}${extraData}`);
      }

      postCompanyOnboard(data)
        .then((response) => {
          if (response.data) {
            handleResponse(response.data);
          }
        })
        .catch((error) => {
          if (error.response) {
            const data = error.response.data;
            if (data) {
              if (data.nonFieldErrors && data.nonFieldErrors.length) {
                handleError(error.response.data.nonFieldErrors[0]);
              } else if (error.response.data.userEmail && error.response.data.userEmail.length) {
                handleError(error.response.data.userEmail[0]);
              }
            }
          }
        });
    }
  };

  const handleResponse = (response) => {
    if (response.uuid) {
      cookies.set('uuid', response.uuid, { path: '/' });
      // setFullName('');
      // setEmail('');
      // setCompany('');
      setSubmitted(false);
      // setPhone('');
      setLoading(false);
      setMessageSent(true);

      setTimeout(() => {
        setLoading(false);
        // setMessageSent(false);
      }, 5000);
    }
  };

  const handleError = (error) => {
    if (error === 'A user is already registered with this email address.') {
      setShowCompanyLogin(true);
      setProcessed(true);
    }
    setSubmitted(false);
    setProcessed(true);
    setLoading(false);
  };

  const handleEmailBlur = (event) => {
    checkEmail(email);
  };

  const validateEmail = () => {
    if (submitted && !email) {
      return 'Enter your email address';
    } else if (email) {
      if (processed) {
        return `You have an existing account with Poly`;
      } else if (!emailValid) {
        return 'Enter a valid email address';
      } else if (!corporateValid) {
        return 'Please enter your company email address';
      }
    }
  };

  return (
    <>
      {!messageSent ? (
        <div
          className={classNames(SharedStyles.form, className)}
          style={{ marginTop: insideBanner ? '0px' : '' }}
          data-test-id='login-form'>
          <Text element='h2' size={Text.Size.H3}>
            {`Work with the best talent and teams on your most important projects.`}
          </Text>

          <InputContainer>
            <TextInput
              className={inputClassName}
              name='fullName'
              label='Your Name'
              error={submitted && !fullName && 'Enter your full name'}
              defaultValue={fullName}
              onKeyPress={handleKeyPress}
              onChange={(e) => setFullName(e.target.value)}
            />
          </InputContainer>
          <InputContainer style={{ position: 'relative' }}>
            <TextInput
              className={inputClassName}
              name='email'
              label='Company Email Address'
              error={validateEmail()}
              defaultValue={email}
              onKeyPress={handleKeyPress}
              onBlur={handleEmailBlur}
              onChange={(e) => setEmail(e.target.value)}
            />
            {showCompanyLogin && (
              <div className={SharedStyles.inputAltAction}>
                <a
                  className={SharedStyles.errorMsgLink}
                  title=''
                  href={`https://hiredigital.com/login?email=${email}`}>
                  Login &rarr;
                </a>
              </div>
            )}
          </InputContainer>
          {/*<InputContainer>
            <TextInput
              name='company'
              label='Company Name'
              error={submitted && !company && 'Enter your company name'}
              defaultValue={company}
              onKeyPress={handleKeyPress}
              onChange={(e) => setCompany(e.target.value)}
            />
          </InputContainer>*/}
          <InputContainer>
            <TextArea
              className={inputClassName}
              name='requirements'
              label='Brief description of your requirements'
              defaultValue={requirements}
              onKeyPress={handleKeyPress}
              onChange={(e) => setRequirements(e.target.value)}
            />
          </InputContainer>

          <InputContainer>
            <PhoneNumber
              className={inputClassName}
              inputClassName={phoneInputClassName}
              phoneCountry={phoneCountry}
              phoneNumber={phone}
              onPhoneCountryChange={setPhoneCountry}
              onPhoneNumberChange={setPhone}
              getCountries={getCountries}
              shouldValidate={false}
            />
          </InputContainer>

          {CustomButton ? (
            <CustomButton
              button={{ url: '#', label: 'Schedule A Call' }}
              onClick={handleSubmit}
              className={buttonClassName}
            />
          ) : (
            <Button
              onClick={handleSubmit}
              type={Button.Type.BLUE}
              style={{ marginTop: 'auto', width: '100%' }}
              isLoading={loading}>
              {'Schedule A Call'}
            </Button>
          )}
        </div>
      ) : (
        <div className={classNames(Styles.meetingIframe, iframeClassName)}>
          <div
            className='meetings-iframe-container'
            data-src={hubspotUrl}
            data-title='Schedule A Call'></div>
        </div>
      )}
    </>
  );
};

export default ScheduleACall;
