import classNames from 'classnames';
import Styles from './Styles.module.scss';

const InputContainer = ({ className, children, noMargin, rateInput, ...props }) => {
  return (
    <div
      className={classNames(
        Styles.inputContainer,
        noMargin && Styles.noMargin,
        rateInput && Styles.rateInputContainer,
        className
      )}
      {...props}>
      {children}
    </div>
  );
};

export default InputContainer;
