/* eslint-disable react-hooks/exhaustive-deps */
import { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import Select from 'react-select';
import classNames from 'classnames';
import TextInput from '@hiredigital/ui/Input/TextInput';
import Styles from './Styles.module.scss';
import FlagStyles from '../Flags.module.scss';

import { validatePhone } from '@hiredigital/lib/helpers/utils';
import debounce from 'lodash/debounce';

const PhoneCountryOption = ({ data, innerRef, innerProps, isSelected }) => {
  const { dialCode, name, cca2 } = data;
  return (
    <div
      ref={innerRef}
      {...innerProps}
      className={classNames(Styles.optionItem, FlagStyles.f16, {
        [Styles.isSelected]: isSelected,
      })}>
      <span
        className={classNames(FlagStyles.flag, FlagStyles?.[cca2?.toLowerCase()], Styles.flag)}
      />
      <span className={Styles.countryText}>{name}</span>
      <span className={Styles.dialCode}>{dialCode}</span>
    </div>
  );
};

const PhoneNumber = ({ icon: Icon, className, inputClassName, ...props }) => {
  const [countries, setCountries] = useState(props.countries || []);
  const [phoneNumber, setPhoneNumber] = useState(props.phoneNumber);
  const [phoneCountry, setPhoneCountry] = useState(props.phoneCountry);
  const [errors, setErrors] = useState({});
  useEffect(() => {
    loadCountryList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    setPhoneNumber(props.phoneNumber);
  }, [props.phoneNumber]);

  useEffect(() => {
    setPhoneCountry(props.phoneCountry);
  }, [props.phoneCountry]);

  const loadCountryList = async () => {
    const response = await props.getCountries?.();
    setCountries(response?.data || []);
  };

  const handlePhoneCodeChange = (value) => {
    setPhoneCountry(value);
    props.onPhoneCountryChange?.(value);
  };

  const handlePhoneNumberChange = (e) => {
    const { value } = e.target;
    setPhoneNumber(value);
    props.onPhoneNumberChange?.(value);

    if (props.shouldValidate) {
      validate(value);
    }
  };

  const validate = useCallback(
    debounce((phone) => {
      const err = {};
      const valid = validatePhone(phone);

      if (!valid) {
        err.phoneNumber = 'Enter a valid phone number.';
      }

      setErrors(err);
      props.onValidate?.(valid);
    }, 250),
    []
  );

  const customFilterOptions = (candidate, input) => {
    return `${candidate.label}${candidate.data.name}${candidate.data.cca2}`
      .toLowerCase()
      .includes(input.toLowerCase());
  };

  return (
    <div className={classNames(Styles.countryPhoneNumber, className, !!Icon && Styles.hasIcon)}>
      <div className={Styles.phoneCountry} data-test-id='phone-country-id'>
        {!!Icon && <Icon className={Styles.icon} />}
        <Select
          className={Styles.selectPhoneCountry}
          value={phoneCountry}
          classNamePrefix='phone-country'
          name='phoneCountry'
          placeholder='Code'
          getOptionLabel={({ dialCode }) => dialCode}
          getOptionRightLabel={({ label }) => label}
          getOptionValue={({ id }) => id}
          options={countries || []}
          filterOption={customFilterOptions}
          onChange={handlePhoneCodeChange}
          components={{ Option: PhoneCountryOption }}
        />
      </div>
      <TextInput
        name='phoneNumber'
        label='Phone Number'
        className={classNames(Styles.fieldPhoneNumber, inputClassName)}
        value={phoneNumber}
        onChange={handlePhoneNumberChange}
        error={props.error || errors?.phoneNumber}
        required={props.required}
      />
    </div>
  );
};

PhoneNumber.propTypes = {
  onPhoneCountryChange: PropTypes.func,
  onPhoneNumberChange: PropTypes.func,
  onValidate: PropTypes.func,
  phoneCountry: PropTypes.object,
  phoneNumber: PropTypes.string,
  countries: PropTypes.array,
  error: PropTypes.string,
  getCountries: PropTypes.func,
  shouldValidate: PropTypes.bool,
};

PhoneNumber.defaultProps = {
  shouldValidate: true,
};

export default PhoneNumber;
