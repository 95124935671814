import { appRequest, request } from './utils';

class DataService {
  constructor(data) {
    this.data = data || {};
    this.hasData = data ? true : false;
  }

  setData(data) {
    this.data = data;
    this.hasData = data ? true : false;
  }

  getData() {
    return this.data;
  }
}

const CountryService = new DataService();
const TimezoneService = new DataService();
const LanguageService = new DataService();
const TopicService = new DataService();

export const getDemographics = async (config = undefined) => {
  const url = `demographics/`;
  return await request.get(url, config);
};

export const getTimezones = async () => {
  if (TimezoneService.hasData) {
    return TimezoneService.getData();
  } else {
    const url = `timezones`;
    const data = await appRequest.get(url);
    TimezoneService.setData(data);
    return data;
  }
};

export const getCountries = async () => {
  if (CountryService.hasData) {
    return CountryService.getData();
  } else {
    const url = `countries`;
    const data = await appRequest.get(url);
    CountryService.setData(data);
    return data;
  }
};

export const getLanguages = async () => {
  if (LanguageService.hasData) {
    return LanguageService.getData();
  } else {
    const url = `languages`;
    const data = await appRequest.get(url);
    LanguageService.setData(data);
    return data;
  }
};

export const getTopics = async () => {
  if (TopicService.hasData) {
    return TopicService.getData();
  } else {
    const url = `topics`;
    const data = await appRequest.get(url);
    TopicService.setData(data);
    return data;
  }
};

export const onSkillLoad = async (config = undefined) => {
  const url = `public/skills/`;
  const response = await request.get(url, config);
  return {
    count: response.data.meta.count,
    options: response.data.results,
    hasMore: response.data.meta.nextPage !== null,
    additional: {
      page: response.data.meta.nextPage,
    },
  };
};

export const getTalentCounts = async (config = undefined) => {
  const url = `public/talent-counts/`;
  return await request.post(url, config);
};

export const onSkillLoadOptions = async (search, loadedOptions, { page }) => {
  const data = { params: { search, page, limit: 20 } };
  const url = `public/skills/`;
  const response = await request.get(url, data);
  return {
    options: response.data.results,
    hasMore: response.data.meta.nextPage !== null,
    additional: {
      page: response.data.meta.nextPage,
    },
  };
};

export const postSkill = async (data, config = undefined) => {
  const url = `public/skills/`;
  return await request.post(url, data, config);
};

export const getCallAvailableTimes = async (config = undefined) => {
  const url = `public/call-available-timeslots/`;
  return await request.get(url, config);
};

export const getCallTimeSlots = async (data = undefined) => {
  const url = `public/call-timeslots/`;
  return await request.post(url, data);
};
